@import '@spotahome/marketplace-common/src/scss/base';

.distance-info {
  @include font-s;
  background-color: transparent;
  margin-top: $spacing-xs;

  @include from-tablet {
    @include font-m;
    margin-top: $spacing-s
  }

  &__wrapper {
    background-color: white;
    border-radius: 4px;
    display: inline-block;
    padding: $spacing-xs $spacing-s;

    @include from-tablet {
      padding: $spacing-s;
    }
  }

  &__wrapper:not(:last-child) {
    margin-bottom: $spacing-xs;
    margin-right: $spacing-xs;
  }

  &__icon {
    margin-right: $spacing-xs;
    vertical-align: middle;
    width: 18px;

    @include from-tablet {
      width: 24px;
    }
  }

  &__time {
    @include font-bold;
  }

  &__transport-mean {
    @include font-bold;
  }
}
