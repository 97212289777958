@import '@spotahome/marketplace-common/src/scss/base';

.interactive-map {
  &__map {
    height: 100%;
    width: 100%;
  }

  &__header {
    border-radius: 4px;
    position: absolute;
    left: $spacing-m;
    top: $spacing-m;
    width: calc(100% - #{$spacing-xl});
    z-index: 1;

    @include from-desktop {
      width: calc(70% - #{$spacing-xl});
    }
  }
}
